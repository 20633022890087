import styled from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Link } from "gatsby"

export const primaryTextColor = "#484848"

export const mch1 = styled.h1`
  font-family: "Roboto", sans-serif;
  color: ${primaryTextColor};
  margin: 0;
`

export const mch2 = styled.h2`
  font-family: "PT Sans", sans-serif;
  color: ${primaryTextColor};
`

export const mch3 = styled.h3`
  font-family: "PT Sans", sans-serif;
  color: ${primaryTextColor};
`

export const mch4 = styled.h4`
  font-family: "PT Sans", sans-serif;
  color: ${primaryTextColor};
`

export const mch5 = styled.h5`
  font-family: "PT Sans", sans-serif;
  color: ${primaryTextColor};
`

export const mch6 = styled.h6`
  font-family: "PT Sans", sans-serif;
  color: ${primaryTextColor};
`

export const mcp = styled.p`
  font-family: "PT Sans", sans-serif;
  color: ${primaryTextColor};
  font-size: 16px;
  margin: 8px 0;
`

export const mcInternalLink = styled(AniLink)`
  color: #005eff;
  font-family: "PT Sans", sans-serif;
  padding-top: 20px;
  text-decoration: none;
  padding-bottom: 5px;
  padding-right: 5px;
  box-shadow: inset 0 -4px 0 0 #eaf8ff;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
  font-size: 18px;

  &:hover {
    padding-bottom: 10px;
    box-shadow: inset 0 -6px 0 0 #1c6fff;
  }
`

export const mcLink = styled(Link)`
  color: #005eff;
  font-family: "PT Sans", sans-serif;
  padding-top: 20px;
  text-decoration: none;
  padding-bottom: 5px;
  padding-right: 5px;
  box-shadow: inset 0 -4px 0 0 #eaf8ff;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
  font-size: 18px;

  &:hover {
    padding-bottom: 10px;
    box-shadow: inset 0 -6px 0 0 #1c6fff;
  }
  
`

export const ProjectSectionTitle = styled(mch3)`
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 10px;
`

/* I know...sorry :( -Mel June 2020 */
export const ProjectSectionParagraphs = styled.div`
  font-family: "PT Sans", sans-serif;
  color: ${primaryTextColor};
  font-size: 16px;
`
